<template>
  <div class="about">
    <heard-vue></heard-vue>
    <!-- 主体区域 -->
    <div class="about-main width-100 posi-rel">
      <div class="about-main-title flex-center">
        STFULFILL is a one-stop transportation solution provider.
      </div>
      <div class="width-100 main-title-center">
        <div class="main-title-img row-start">
          <div class="title-text">
            <div>We make your business easier, faster and simpler.</div>
            <div>
              We provide order fulfillment, wholesale, product sourcing, warehousing,
              shipping and more.
            </div>
          </div>
          <img src="../../assets/about-1.png" alt="" />
        </div>
      </div>
      <div class="main-center flex-center" v-if="aboutObj.length>0">
        <img :src="aboutObj[0].icon" alt="" />
        <div class="main-item direction-between">
          <div class="main-title width-100">
            <div class="width-100 flex-center">
              <div class="item-border bg-color"></div>
            </div>
            <div class="main-color title">{{aboutObj[0].name}}</div>
          </div>
          <div class="main-introduce width-100 direction-between">
            <div class="width-100 introduce-text">
              {{aboutObj[0].content}}
            </div>
            <div class="introduce-button flex-center">
              <div class="bg-color flex-center pointer" @click="navTo('/login')">LOGIN</div>
            </div>
            <div class="item-border item-border-one bg-color"></div>
          </div>
        </div>
      </div>
      <div class="main-center main-center-one flex-center" v-if="aboutObj.length>1">
        <div class="main-item direction-between">
          <div class="main-title width-100">
            <div class="width-100 flex-center">
              <div class="item-border bg-color"></div>
            </div>
            <div class="main-color title">{{aboutObj[1].name}}</div>
          </div>
          <div class="main-introduce width-100 direction-between">
            <div class="width-100 introduce-text">
              {{aboutObj[1].content}}
            </div>
            <div class="introduce-button flex-center">
              <div class="bg-color flex-center pointer" @click="navTo('/login')">LOGIN</div>
            </div>
            <div class="item-border item-border-one bg-color"></div>
          </div>
        </div>
        <img :src="aboutObj[1].icon" alt="" />
      </div>
      <div class="main-center main-center-two flex-center" v-if="aboutObj.length>2">
        <img :src="aboutObj[2].icon" alt="" />
        <div class="main-item direction-between">
          <div class="main-title width-100">
            <div class="width-100 flex-center">
              <div class="item-border bg-color"></div>
            </div>
            <div class="main-color title">{{aboutObj[2].name}}</div>
          </div>
          <div class="main-introduce width-100 direction-between">
            <div class="width-100 introduce-text">
            {{aboutObj[2].content}}
            </div>
            <div class="introduce-button flex-center">
              <div class="bg-color flex-center pointer" @click="navTo('/login')">LOGIN</div>
            </div>
            <div class="item-border item-border-one bg-color"></div>
          </div>
        </div>
      </div>
      <!-- <img src="../../assets/about-5.png" v-if="!emailShow" class="emain-img posi-abs pointer" alt="" @click="emailShow=true" /> -->
    </div>
    <mail-vue v-if="emailShow" @hide="emailShow=false"></mail-vue>
    <feet-vue></feet-vue>
  </div>
</template>

<script>
import heardVue from "../../components/heard.vue";
import feetVue from "../../components/feet.vue";
import mailVue from "../../components/mail.vue";
import api from "../../api/index"
export default {
  components: {
    heardVue,
    feetVue,
    mailVue
  },

  data() {
    return {
      emailShow:false,
      aboutObj:[]
    };
  },

  created() {
    this.getAbout()
  },

  mounted() {},

  methods: {
    navTo(url){
      this.$router.push(url)
    },
    getAbout(){
      api.getAbout().then(res=>{
       this.aboutObj=res.data
      })
    }
  },
};
</script>
<style lang="less" scoped>
.about {
  width: 100%;
  .about-main {
    background-color: #fff;
    background-image: url("../../assets/about.png");
    background-repeat: no-repeat;
    background-size: 100% 700px;
    padding-top: 80px;
    padding-bottom: 89px;
    .about-main-title {
      font-weight: bold;
      color: #ffffff;
      font-size: 48px;
      margin-bottom: 140px;
      white-space: nowrap;
    }
    .main-title-center {
      padding-left: 340px;
    }
    .main-title-img {
      width: 100%;
      margin-bottom: 85px;
      flex-wrap: nowrap;
      .title-text {
        color: #ffffff;
        font-size: 24px;
        div:first-child {
          margin-bottom: 60px;
        }
      }
      img {
        width: 496px;
        height: 445px;
      }
    }
    .main-center {
      margin-bottom: 120px;
      align-items: flex-end;
      img {
        width: 580px;
        height: 386px;
        margin-right: 167px;
      }
      .main-item {
        width: 446px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        .item-border {
          width: 110px;
          height: 8px;
          border-radius: 0px 0px 4px 4px;
        }
        .item-border-one{
          border-radius: 4px 4px 0 0;
        }
        .main-title {
          margin-bottom: 48px;
          .title {
            width: 100%;
            padding-left: 64px;
            margin-top: 50px;
            font-weight: bold;
            font-size: 24px;
          }
        }
        .main-introduce {
          color: #666666;
          font-size: 18px;
          .introduce-text {
            padding: 0 10px 0 67px;
            margin-bottom: 31px;
            line-height: 30px;
            
            font-family: Noto Serif;
          }
          .introduce-button {
            margin-bottom: 31px;
            div {
              width: 96px;
              height: 40px;
              background: linear-gradient(270deg, #ff652b 0%, #ff966e 100%);
              border-radius: 20px;
              font-weight: bold;
              color: #ffffff;
              font-size: 18px;
            }
          }
        }
      }
    }
    .main-center-one {
      margin-bottom: 120px;
      img {
        margin-right: 0;
        margin-left: 167px;
      }
    }
    .main-center-two {
      margin-bottom: 0;
      img {
        margin-right: 227px;
        width: 537px;
        height: 358px;
      }
    }
    .emain-img {
      width: 64px;
      height: 64px;
      left: 60px;
      bottom: 84px;
    }
  }
}
</style>
